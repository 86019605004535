import React, { useEffect, useState } from "react"
import { Modal } from "react-bootstrap"
import ReactCSSTransitionGroup from 'react-transition-group'
import { getUser, setUser, isLoggedIn } from "../../../services/auth"
import config from '../../../config/config'
import axios from "axios";

import styles from "./CreateBoardModal.scss"
import { getBoardFromLocalStorage, updateBoard } from "../../../state/board"
import { connect } from "react-redux"

const CreateBoardModal = ({ show, handleClose, objectClass, objectID, variantID, dispatch, currentBoards }) => {
  const [modalCreate, setModalCreate] = useState(false);
  //var showSave = true;
  var isShowing = show;
  var Member = getUser();

  const [saveButton, setSaveButton] = useState([]);
  useEffect(() => {
    dispatch(getBoardFromLocalStorage())
  }, [])

  useEffect(() => {
    setSaveButton(currentBoards.map(b => {
      const items = JSON.parse(b.Items)
      let isSaved = false
      for (const key in items) {
        if (key.includes(objectClass) && items[key].ID == objectID) {
          isSaved = isSaved || !variantID || (variantID && variantID == items[key].VariantID)
        }
      }
      return {
        boardId: b.ID,
        isSaved,
      }
    }))
  }, [setSaveButton, objectID, objectClass, variantID, currentBoards])

  const saveBoard = (boardID, isSaved) => {
    if (isLoggedIn()) {
      var _data = {
        enu: btoa(Member.Email),
        objectClass: objectClass,
        objectID: objectID,
        variantID: variantID,
        boardID: boardID,
      };

      axios.post(config.host + `/tribecaapi/${isSaved ? 'removefromboard' : 'addtoboard'}`, _data)
        .then(response => {
          let _response_data = response.data;
          let _response_boards = Object.keys(_response_data).map(key => _response_data[key]);
          let _board = _response_boards.find(x => x.ID === boardID);
          if (_board) {
            let _board_items = _board.Items;
            let _board_items_objs = JSON.parse(_board_items);
            let _board_items_arr = Object.keys(_board_items_objs).map(key => _board_items_objs[key]);
            let _item_exist = _board_items_arr.find(x => x.ID == objectID);
            if (_item_exist || isSaved) {
              setSaveButton(prevButton => {
                return [
                  ...prevButton.filter(pb => pb.boardId != boardID),
                  {
                    boardId: boardID,
                    isSaved: !isSaved
                  }
                ]
              });
              // not done yet, working on buttons conflict
              Member.Boards = _response_data;
              dispatch(updateBoard(Object.keys(Member.Boards).map(key => Member.Boards[key])))
              setUser(Member);
              setTimeout(function () {
                handleClose()
              }, 1000)
            }
          }
        }).catch(function (error) {
        });
    }
  }
  const createBoard = (event) => {
    event.preventDefault();
    let boardname = event.target.elements.boardname.value;
    if (isLoggedIn()) {
      var _data = {
        enu: btoa(Member.Email),
        boardname: boardname,
      };

      axios.post(config.host + "/tribecaapi/createboard", _data)
        .then(response => {
          let _response_data = response.data;
          if (_response_data) {
            Member.Boards = _response_data;
            setUser(Member);
            dispatch(updateBoard(Object.keys(Member.Boards).map(key => Member.Boards[key])))
          }
          setModalCreate(false);
        }).catch(function (error) {
        });
    }
  }
  return (
    <Modal
      className="boardModal"
      show={isShowing}
      onHide={handleClose}
      onEnter={() => setModalCreate(false)}
      animation={true}
    >
      <div className="boardModal-inner">
        <div className="btn-close" onClick={() => handleClose()}></div>
        {modalCreate ?
          <>
            <h4>CREATE A BOARD</h4>
            <form onSubmit={event => { createBoard(event) }}>
              <div className="form-group">
                <input name="boardname" placeholder="New board name" className="form-control" />
              </div>
              <div className="d-flex justify-content-between mt-5">
                <button className="btn text-cancel px-0" onClick={() => setModalCreate(false)}>
                  Cancel
                </button>
                <button className="btn btn-black btn-lg btn-w160">CREATE</button>
              </div>
            </form>
          </>
          :
          <>
            <h4>SAVE TO BOARD</h4>
            <div className="board-list">
              {currentBoards.map((board, idx) => {
                const button = saveButton.find(sb => sb.boardId == board.ID)
                return (
                  <div key={`board-${board.ID}`} className={['board-list-item', button && button.isSaved ? 'active' : ''].join(' ')}>
                    <p><a href={`/account/board/?id=${board.ID}`}>{board.Name}</a></p>
                    {button && button.isSaved ? (
                    <div
                      className="d-flex flex-column align-items-center mr-1 mb-n2 board-list-toggle loved"
                      onClick={(e) => {
                        saveBoard(board.ID, button.isSaved)
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="28"
                        height="28"
                        viewBox="0 0 35 35"
                      >
                        <path
                          id="Path_878"
                          data-name="Path 878"
                          d="M-4.556-1.307c-5.906-10.042-18.85-3.3-16.35,8.076C-18.458,17.9-4.556,23.792-4.556,23.792S9.348,17.9,11.794,6.769C14.295-4.611,1.352-11.349-4.556-1.307Z"
                          transform="translate(21.982 6.975)"
                          stroke="currentColor"
                          fill="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeMiterlimit="10"
                          strokeWidth="1.5"
                        />
                      </svg>
                      <div className="clear-item"></div>
                      LOVED
                    </div>
                  ) : (
                    <div
                      className="d-flex flex-column align-items-center mr-1 mb-n2 board-list-toggle"
                      onClick={(e) => {
                        if (!button.isSaved) saveBoard(board.ID, button.isSaved)
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="28"
                        height="28"
                        viewBox="0 0 35 35"
                      >
                        <path
                          id="Path_878"
                          data-name="Path 878"
                          d="M-4.556-1.307c-5.906-10.042-18.85-3.3-16.35,8.076C-18.458,17.9-4.556,23.792-4.556,23.792S9.348,17.9,11.794,6.769C14.295-4.611,1.352-11.349-4.556-1.307Z"
                          transform="translate(21.982 6.975)"
                          stroke="#a2a29d"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeMiterlimit="10"
                          strokeWidth="1.5"
                        />
                      </svg>
                      ADD
                    </div>
                  )}
                  </div>
                )
              })}
            </div>
            <div className="d-flex justify-content-between">
              <button className="btn-create" onClick={() => setModalCreate(true)}>
                <svg width='43px' height='43px'>
                  <use xlinkHref='/img/icons.svg#icon-circle-plus'></use>
                </svg>
                CREATE NEW BOARD
              </button>
            </div>
          </>
        }
      </div>
    </Modal>
  )
}

const mapStateToProps = (state) => ({
  currentBoards: state.board.all,
})

export default connect(mapStateToProps, null)(CreateBoardModal)
