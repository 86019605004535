import React from "react";
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper/core';

SwiperCore.use([Navigation]);
const SwiperList = ({ title, navClass }) => {
  const params = {
    spaceBetween: 10,
    watchSlidesVisibility: true,
    navigation: {
      prevEl: `.swiper-prev-${navClass}`,
      nextEl: `.swiper-next-${navClass}`,
    },
    breakpoints: {
      1300: {
        slidesPerView: 4,
      },
      992: {
        slidesPerView: 3,
      },
      768: {
        slidesPerView: 2,
      },
      0: {
        slidesPerView: 1,
      },
    },
  }

  return (
    <div className="swiper-list-wrap">
      <h2 className="main-title">{title}</h2>
      <div className="swiper-list-inner">
        <Swiper {...params}>
          <SwiperSlide>
            <a href="#" className="product-items">
              <div className="product-items-thumb">
                <img src="img/products/item-6.png"></img>
              </div>
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a href="#" className="product-items">
              <div className="product-items-thumb">
                <img src="img/products/item-6.png"></img>
              </div>
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a href="#" className="product-items">
              <div className="product-items-thumb">
                <img src="img/products/item-6.png"></img>
              </div>
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a href="#" className="product-items">
              <div className="product-items-thumb">
                <img src="img/products/item-6.png"></img>
              </div>
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a href="#" className="product-items">
              <div className="product-items-thumb">
                <img src="img/products/item-6.png"></img>
              </div>
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a href="#" className="product-items">
              <div className="product-items-thumb">
                <img src="img/products/item-6.png"></img>
              </div>
            </a>
          </SwiperSlide>
          
        </Swiper>
        <div className={`swiper-ct-prev swiper-prev-${navClass}`}></div>
        <div className={`swiper-ct-next swiper-next-${navClass}`}></div>
      </div>
    </div>
  )
}

SwiperList.propTypes = {
  title: PropTypes.string,
  navClass: PropTypes.string.isRequired
};

export default SwiperList
