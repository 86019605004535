import React, { useState, useEffect, useRef, useCallback } from "react"
import PropTypes from "prop-types"
import Swiper from "react-id-swiper"

const RuleSwiperItemsList = ({ title, titleClass, navClass, rule }) => {
  const swiperRef = useRef(null)
  const [currentIndex, updateCurrentIndex] = useState(0)
  const [prevNav, setPrevNav] = useState(false)
  const [nextNav, setNextNav] = useState(true)

  const params = {
    spaceBetween: 10,
    watchSlidesVisibility: true,
    allowTouchMove: false,
    navigation: {
      nextEl: `.swiper-next-${navClass}`,
      prevEl: `.swiper-prev-${navClass}`,
    },
    on: {
      init: () => {
        window.dispatchEvent(new Event("resize"))
      },
      slideChange: e => {
        e.activeIndex > 0 ? setPrevNav(true) : setNextNav(true)
      },
      reachEnd: () => setNextNav(false),
      reachBeginning: () => setPrevNav(false),
      slideNextTransitionStart: () => setPrevNav(true),
      slidePrevTransitionStart: () => setNextNav(true),
    },
    breakpoints: {
      1200: {
        slidesPerView: 4,
      },
      992: {
        slidesPerView: 3,
      },
      768: {
        slidesPerView: 2,
        allowTouchMove: true,
      },
      0: {
        slidesPerView: 2,
        allowTouchMove: true,
      },
    },
  }

  const goNext = e => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext()
    }
  }

  const goPrev = e => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev()
    }
  }
  return (
    <>
      {rule.items.length > 0 && (
        <div className="swiper-list-wrap">
          <div className="title-swiper mb-3">
            <p className={`slide-title ${titleClass}`}>{title}</p>
            {rule.items.length > 4 && (
            <>
            <div
              className={`swiper-ct-prev swiper-prev-${navClass}${
                prevNav ? "" : " swiper-button-disabled"
              }`}
              onClick={goPrev}
            ></div>
            <div
              className={`swiper-ct-next swiper-next-${navClass}${
                nextNav ? "" : " swiper-button-disabled"
              }`}
              onClick={goNext}
            ></div>
            </>
            )}
          </div>
          <div className="swiper-list-inner">
            <Swiper {...params} ref={swiperRef}>
              {rule.items.map((item, idx) => (
                <div
                  className={`swiper-slide${item.Type === 'HomeDesign' ? ' floorplan-tile' : ''}`}
                  key={`related-item-${idx}-${item.URLSegment}`}
                >
                  <a href={item.Link} className="product-items">
                    <div
                      className={`product-items-thumb ${item.ImagePosition}`}
                    >
                      <img src={item.Image} alt=""></img>
                      <div className="grid-caption">
                        <h4>{item.Title}</h4>
                        {item.Type == "HomeDesign" ? (
                            <>
                            {item.VariantName && (
                                <div className="grid-bottom">
                                    <p className="variant-name">{item.VariantName}</p>                          

                                    <div className="d-flex mx-n2">
                                      <div className="mx-2 item-feature">
                                        {item.Bed}
                                        <svg width="24" height="24">
                                          <use xlinkHref="/img/icons.svg#icon-bed-stroke"></use>
                                        </svg>
                                      </div>
                                      <div className="mx-2 item-feature">
                                        {item.Bath}
                                        <svg width="24" height="24">
                                          <use xlinkHref="/img/icons.svg#icon-bath-stroke"></use>
                                        </svg>
                                      </div>
                                      <div className="mx-2 item-feature">
                                        {item.Living}
                                        <svg width="24" height="24">
                                          <use xlinkHref="/img/icons.svg#icon-sofa-stroke"></use>
                                        </svg>
                                      </div>
                                      <div className="mx-2 item-feature">
                                        {item.Garage}
                                        <svg width="24" height="24">
                                          <use xlinkHref="/img/icons.svg#icon-garages-stroke"></use>
                                        </svg>
                                      </div>
                                    </div>
                                </div>
                            )}
                        </>
                        ):(
                        <>
                            {item.VariantName && (
                            <div className="grid-bottom">
                                <p className="variant-name">{item.VariantName}</p>    
                            </div>
                            )}
                        </>
                        )}
                      </div>
                    </div>
                  </a>
                </div>
              ))}
            </Swiper>
          </div>
        </div>
      )}
    </>
  )
}

RuleSwiperItemsList.propTypes = {
  title: PropTypes.string,
  navClass: PropTypes.string.isRequired,
  items: PropTypes.string,
}

export default RuleSwiperItemsList
